import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { usePageTitle } from '../../../../hooks/useMeta';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import Button from '../../../buttons/Button';
import Form from '../../../inputs/Form';
import Spinner from '../../../utils/Spinner'
import useApi from '../../../../hooks/useApi';
import { getListOptions } from '../../../../services/listService';
import { getRestaurantOffer, createRestaurantOffer, updateRestaurantOffer, deleteRestaurantOffer, getRelatedPartnersToRestaurantOffer, getRestaurantType, getRestaurantImage } from '../../../../services/restaurantService';
import { toast } from 'react-toastify';
import RestaurantAppPreview from '../../../app/RestaurantAppPreview';
import PartnerCard from './PartnerCard';
import Modal from '../../../utils/Modal';
import useModal from '../../../../hooks/useModal';
import { useSelector } from 'react-redux';
import useLangNavigate from '../../../../hooks/useLangNavigate';

export const OfferView = ({ tKey = 'offerView.' }) => {   
    const { offerId } = useParams(); 
    usePageTitle(offerId ? 'offerView' : 'newOffer');
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const { isModalOpen, openModal, closeModal } = useModal();
    const [modalContent, setModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState([])
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [restaurantType, setRestaurantType] = useState(null);
    const [restaurantImage, setRestaurantImage] = useState(null);
    const [offerTypesOptions, setOfferTypesOptions] = useState([]);
    const [partners, setPartners] = useState([]);
    const {
        request: getOfferTypesRequest,
    } = useApi(getListOptions);
    const {
        request: offerGetRequest,
    } = useApi(getRestaurantOffer);
    const {
        request: offerCreateRequest,
    } = useApi(createRestaurantOffer);
    const {
        request: offerUpdateRequest,
    } = useApi(updateRestaurantOffer);
    const {
        request: offerDeleteRequest,
    } = useApi(deleteRestaurantOffer);
    const {
        request: getRestaurantTypeRequest,
    } = useApi(getRestaurantType);
    const {
        request: getRestaurantImageRequest,
    } = useApi(getRestaurantImage);

    const restaurantId = useSelector((state) => state.user.restaurantId);

    const fetchOfferTypes = useCallback(async () => {
        await getOfferTypesRequest('offertype')
            .then((response) => {                
                setOfferTypesOptions(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [getOfferTypesRequest]);

    const fetchRestaurantType = useCallback(async (restaurantId) => {
        await getRestaurantTypeRequest(restaurantId)
            .then((response) => {
                setRestaurantType(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [getRestaurantTypeRequest]);

    const fetchRestaurantImage = useCallback(async (restaurantId) => {
        await getRestaurantImageRequest(restaurantId)
            .then((response) => {
                setRestaurantImage(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [getRestaurantImageRequest]);

    const convertOfferToFormData = useCallback((offer) => {
        setFormData({ ...offer,
            offerType: { label: offer.offertype ? t(`utils.offerTypes.${offer.offertype.label}`) : null, value: offer.offertype || null }, 
        });
    }, [t]);

    useEffect(() => {
        fetchOfferTypes();
        fetchRestaurantType(restaurantId);
        fetchRestaurantImage(restaurantId);
    }, [fetchOfferTypes, fetchRestaurantType, fetchRestaurantImage, restaurantId]);

    useEffect(() => {
        if (offerId && offerTypesOptions) {
            setIsLoading(true);
            offerGetRequest(offerId)
                .then((response) => {
                    const offer = response.data;
                    convertOfferToFormData(offer);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                    toast.error(t(`${tKey}error`));
                })
                .finally(() => {
                    setIsLoading(false);
                });      
            getRelatedPartnersToRestaurantOffer(restaurantId, { offerId }).then((response) => {
                setPartners(response.data);
            }).catch((error) => {
                console.error(error);
            });
        } 
    }, [offerId, offerTypesOptions, convertOfferToFormData, offerGetRequest, t, tKey, restaurantId]);

    useEffect(() => {
        if(offerTypesOptions && offerTypesOptions.length > 0) {
            setFields([
                {
                    name: 'offerType',
                    label: t(`${tKey}offerType.placeholder`),
                    placeholder: t(`${tKey}offerType.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    options: offerTypesOptions.map(option => ({
                        ...option,
                        label: t(`utils.offerTypes.${option.label}`)
                    })),
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.offerType.required`);
                        return '';
                    },
                    optionFormat: (option) => {
                        return option.label;
                    },
                },
                {
                    name: 'value',
                    label: t(`${tKey}value.placeholder`),
                    placeholder: t(`${tKey}value.placeholder`),
                    type: formData.offerType?.value.requiresValue ? 'number' : 'hidden',
                    class: 'w-100',
                    inputContainerClassName: 'input-percent',
                    validate: (value) => {
                        if(!formData.offerType || !formData.offerType.value.requiresValue) return '';
                        if(!value) return t(`${tKey}errors.value.required`);
                        if(value <= 0) return t(`${tKey}errors.value.min`);
                        return '';
                    },
                },
                {
                    name: 'distance',
                    label: t(`${tKey}distance.placeholder`),
                    placeholder: t(`${tKey}distance.placeholder`),
                    type: 'number',
                    class: 'w-100',
                    inputContainerClassName: 'input-km',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.distance.required`);     
                        if(value <= 0) return t(`${tKey}errors.distance.min`);                   
                        return '';
                    },
                },
                {
                    name: 'maximumSubscribers',
                    label: t(`${tKey}maximumSubscribers.placeholder`),
                    placeholder: t(`${tKey}maximumSubscribers.placeholder`),
                    type: 'number',
                    class: 'w-100',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.maximumSubscribers.required`);
                        if(value < 0) return t(`${tKey}errors.maximumSubscribers.min`);
                        return '';
                    },
                },
            ]);
        }
    }, [offerTypesOptions, formData.offerType?.value.requiresValue, formData.offerType, t, tKey]);

    useEffect(() => {
        if (!formData.offerType?.value.requiresValue && formData.value !== null)
            setFormData((prevFormData) => ({ ...prevFormData, value: null }));
    }, [formData.offerType?.value.requiresValue, formData.value]);

    const attemptCreateOffer = async (e) => {
        e.preventDefault();
        await offerCreateRequest(restaurantId, formData)
            .then(() => {
                toast.success(t(`${tKey}success`));
                navigate('dashboard/partners');
            })
            .catch((error) => {
                toast.error(t(`${tKey}error`));
                console.error(error);
            });
    };

    const attemptUpdateOffer = async (e) => {
        e.preventDefault();
        await offerUpdateRequest(restaurantId, { ...formData, offerId, restaurantId})
            .then(() => {
                toast.success(t(`${tKey}success`));
                navigate('dashboard/partners');
            })
            .catch((error) => {
                toast.error(t(`${tKey}error`));
                console.error(error);
            });
    };

    const attemptDelete = async () => {
        setModalContent(<>
            <h5 className='mt-0'>{t(`${tKey}delete.title`)}</h5>
            <p>{t(`${tKey}delete.text`)}</p>                                
        </>);
        openModal();
    };

    const deleteCurrentOffer = async () => {
        const payload = { offerId };
        await offerDeleteRequest(restaurantId, payload)
            .then(() => {
                toast.success(t(`${tKey}delete.success`));
                navigate('dashboard/partners');
            })
            .catch((error) => {
                toast.error(t(`${tKey}delete.error`));
                console.error(error);
            })
            .finally(() => {
                setModalContent(null);
                closeModal();
            });
    };

    return (<>
        <div className='dashboard-content w-50 flex column'>
            {isLoading 
            ? <Spinner size='3x' variant='danger' label={t('utils.data.loading')} />
            : <Form fields={fields} currentForm={formData} setFormDetails={setFormData} setIsValid={setIsValid} fieldClass='bg-white' />}
            {partners && partners.length > 0 && (<>
                <p className='mt-4'>{t(`${tKey}partners`, { count: partners.length })}</p>
                <div className='flex-grow h-100 overflow-y-scroll'>
                    {partners.map((partner, index) => (
                        <PartnerCard key={index} partner={partner} />
                    ))}
                </div>
            </>)}
        </div>
        <div className='dashboard-preview w-50'>
            <div className='preview-section'>
                {restaurantType && 
                    <RestaurantAppPreview img={restaurantImage?.imagePath} restaurantType={restaurantType} chip={formData.offerType?.label} bottomClass='bg-pastel-warning' />
                }
            </div>
            <div className='publish-section flex align-center justify-between'>
                <div>
                    {offerId && <Button variant='white' onClick={attemptDelete}>
                        <FontAwesomeIcon size='2x' icon={faTrashAlt} />
                    </Button>}
                </div>
                <div className='flex gap-1'>
                    <Button disabled={!isValid} onClick={offerId ? attemptUpdateOffer : attemptCreateOffer}>
                        {t(`${tKey}${offerId ? 'btnUpdate' : 'btnPublish'}`)}
                    </Button>                                         
                </div>
            </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteCurrentOffer}>
            {modalContent}
        </Modal>
    </>);
};

export default OfferView;
