import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../../hooks/useLangNavigate";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import {
  postCompanyForm,
  postRestaurantForm,
} from "../../services/authService";
import { useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/pro-light-svg-icons";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-thin-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../components/buttons/Button";
import Sidebar from "../../components/layout/Sidebar";
import StepOne from "../../components/auth/register/StepOne";
import StepCompany from "../../components/auth/register/StepCompany";
import StepTwoRestaurant from "../../components/auth/register/StepTwoRestaurant";
import StepThreeRestaurant from "../../components/auth/register/StepThreeRestaurant";
import StepFourRestaurant from "../../components/auth/register/StepFourRestaurant";
import StepFiveRestaurant from "../../components/auth/register/StepFiveRestaurant";
import StepSixRestaurant from "../../components/auth/register/StepSixRestaurant";
import Review from "../../components/auth/register/Review";
import ConfirmRegister from "../../components/auth/register/ConfirmRegister";
import { usePageTitle } from "../../hooks/useMeta";

function Register() {
  usePageTitle("register");
  const defaultMenu = useMemo(() => {
    return [{ tKey: `register.step1.`, isActive: true }];
  }, []);
  const { referralCode } = useParams();
  const { state } = useLocation();
  const navigate = useLangNavigate();
  const {
    error: companyFormError,
    status: companyFormStatus,
    loading: companyFormLoading,
    request: postCompanyFormRequest,
  } = useApi(postCompanyForm);
  const {
    error: restaurantFormError,
    status: restaurantFormStatus,
    loading: restaurantFormLoading,
    request: postRestaurantFormRequest,
  } = useApi(postRestaurantForm);
  const initialStep = state?.initialStep || 1;
  const [step, setStep] = useState(initialStep);
  const [role, setRole] = useState(null);
  const [isStepValid, setIsStepValid] = useState(false);
  const [menuList, setMenuList] = useState(defaultMenu);
  const [formData, setFormData] = useState({});
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (companyFormError)
      toast.error(companyFormError.message || "An error occurred");
    if (restaurantFormError)
      toast.error(restaurantFormError.message || "An error occurred");
  }, [companyFormError, restaurantFormError]);

  useEffect(() => {
    if (restaurantFormStatus === 200 && user) {
      setTimeout(() => {
        navigate("dashboard");
      }, 5000);
    }
  }, [restaurantFormStatus, user, navigate]);

  useEffect(() => {
    if (step === 1) setFormData({});
  }, [step]);

  const updateMenuListActiveStep = useCallback(
    (newStep) => {
      setMenuList(
        menuList.map((item, index) => ({
          ...item,
          isActive: index === newStep - 1,
        }))
      );
    },
    [menuList]
  );

  const nextStep = useCallback(() => {
    setStep((prev) => {
      let newStep = prev + 1;
      if (isAuthenticated && (newStep === 4 || newStep === 6))
        newStep = newStep + 1;
      updateMenuListActiveStep(newStep);
      setIsStepValid(false);
      return newStep;
    });
  }, [updateMenuListActiveStep, isAuthenticated]);

  const prevStep = () => {
    setStep((prev) => {
      let newStep = prev - 1;
      if (isAuthenticated && (newStep === 4 || newStep === 6))
        newStep = newStep - 1;
      updateMenuListActiveStep(newStep);
      setIsStepValid(false);
      if (newStep === 1) setFormRole(null);
      return newStep;
    });
  };

  const getStepSuffix = () => {
    if (companyFormStatus === 200) return "Confirm";
    if (!role) return step;
    if (step === menuList.length) return "Review";
    if (role === "company" && step > 1) return "Company";
    return step;
  };

  const setFormDetails = useCallback((data) => {
    setFormData((prev) => ({ ...prev, ...data }));
  }, []);

  const setFormRole = useCallback(
    (selectedRole) => {
      setRole(selectedRole);
      setFormData((prev) => ({ ...prev, role: selectedRole }));
      if (selectedRole === "restaurant") {
        if (!isAuthenticated) {
          setMenuList([
            ...defaultMenu,
            { tKey: `register.step2.`, isActive: false },
            { tKey: `register.step3.`, isActive: false },
            { tKey: `register.step4.`, isActive: false },
            { tKey: `register.step5.`, isActive: false },
            { tKey: `register.step6.`, isActive: false },
            { tKey: `register.stepReview.`, isActive: false },
          ]);
          return;
        } else {
          setMenuList([
            ...defaultMenu,
            { tKey: `register.step2.`, isActive: false },
            { tKey: `register.step3.`, isActive: false },
            { tKey: `register.step4.`, isActive: false },
            { tKey: `register.step5.`, isActive: false },
            { tKey: `register.step6.`, isActive: false },
            { tKey: `register.stepReview.`, isActive: false },
          ]);
          return;
        }
      }
      if (selectedRole === "company") {
        setMenuList([
          ...defaultMenu,
          { tKey: `register.stepCompany.`, isActive: false },
          { tKey: `register.stepReview.`, isActive: false },
        ]);
        return;
      }
      setMenuList(defaultMenu);
    },
    [isAuthenticated, defaultMenu]
  );

  const setOwnerAvatar = useCallback(
    (avatar) => {
      setFormData({ ...formData, ownerImage: avatar });
    },
    [setFormData, formData]
  );

  const isNextDisabled = () => {
    return !role || !isStepValid;
  };

  const goToLogin = () => {
    navigate("login");
  };

  const registerUserAndEntity = async () => {
    if (role === "company") {
      try {
        await postCompanyFormRequest(
          { ...formData, referralCode: referralCode || null },
          !isAuthenticated
        );
      } catch (error) {
        console.error("Failed to post company form:", error);
      }
    } else if (role === "restaurant") {
      try {
        await postRestaurantFormRequest(
          { ...formData, referralCode: referralCode || null },
          !isAuthenticated
        );
      } catch (error) {
        console.error("Failed to post restaurant form:", error);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setFormRole("restaurant");
      setFormDetails({
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        function: user.occupiedFunction,
        ownerImage: user.imagePath,
        phone: user.phone,
      });
    }
  }, [isAuthenticated, user, setFormRole, setFormDetails]);

  const displayStep = () => {
    if (companyFormStatus === 200)
      return (
        <ConfirmRegister
          role={role}
          lastname={formData?.lastname}
          firstname={formData?.firstname}
          entityName={formData?.name}
        />
      );
    if (!role)
      return <StepOne setRole={setFormRole} setIsValid={setIsStepValid} />;
    if (role === "company")
      switch (step) {
        case 1:
          return (
            <StepOne
              currentForm={formData}
              setRole={setFormRole}
              setIsValid={setIsStepValid}
              setFormData={setFormDetails}
            />
          );
        case 2:
          return (
            <StepCompany
              currentForm={formData}
              setCompanyDetails={setFormDetails}
              setIsValid={setIsStepValid}
            />
          );
        default:
          return (
            <Review
              role={role}
              formData={formData}
              setIsValid={setIsStepValid}
            />
          );
      }
    if (role === "restaurant")
      if (restaurantFormStatus === 200 && !isAuthenticated)
        return (
          <ConfirmRegister
            role={role}
            lastname={formData?.lastname}
            firstname={formData?.firstname}
            entityName={formData?.name}
          />
        );
    switch (step) {
      case 1:
        return <StepOne setRole={setFormRole} setIsValid={setIsStepValid} />;
      case 2:
        return (
          <StepTwoRestaurant
            currentForm={formData}
            setRestaurantDetails={setFormDetails}
            setIsValid={setIsStepValid}
          />
        );
      case 3:
        return (
          <StepThreeRestaurant
            currentForm={formData}
            name={formData.name}
            restaurantType={formData.restaurantType.value}
            setRestaurantPicture={setFormDetails}
            setIsValid={setIsStepValid}
          />
        );
      case 4:
        return (
          <StepFourRestaurant
            currentForm={formData}
            setOwnerDetails={setFormDetails}
            setIsValid={setIsStepValid}
          />
        );
      case 5:
        return (
          <StepFiveRestaurant
            currentForm={formData}
            setBankDetails={setFormDetails}
            setIsValid={setIsStepValid}
          />
        );
      case 6:
        return (
          <StepSixRestaurant
            currentForm={formData}
            setCredentialsDetails={setFormDetails}
            setIsValid={setIsStepValid}
          />
        );
      default:
        return (
          <Review
            role={role}
            formData={formData}
            setIsValid={setIsStepValid}
            setOwnerAvatar={setOwnerAvatar}
          />
        );
    }
  };

  return (
    <div className="sidebar-page-wrapper">
      <Sidebar
        menuList={menuList}
        isClickable={false}
        count={true}
        title={t(`register.step${getStepSuffix()}.header`)}
        description={t(`register.step${getStepSuffix()}.description`)}
        showLogout={false}
      />
      <div id="register" className="content">
        <div className="register-back">
          <Button variant="tertiary" onClick={goToLogin}>
            <FontAwesomeIcon size="xl" icon={faArrowLeftLong} />
          </Button>
        </div>
        <div className="mt-4">{displayStep()}</div>
        {companyFormStatus === 200 || restaurantFormStatus === 200 ? null : (
          <div className="btn-group mt-auto">
            {(user !== null && step > 2) || (!isAuthenticated && step > 1) ? (
              <Button variant="primary" onClick={prevStep}>
                <FontAwesomeIcon
                  className="mr-2"
                  size="xl"
                  icon={faArrowLeft}
                />
                <span>{t(`register.previousStep`)}</span>
              </Button>
            ) : null}
            {step < menuList.length && (
              <Button
                variant="primary"
                disabled={isNextDisabled()}
                onClick={nextStep}
              >
                <span>{t(`register.nextStep`)}</span>
                <FontAwesomeIcon
                  className="ml-2"
                  size="xl"
                  icon={faArrowRight}
                />
              </Button>
            )}
            {step === 3 && role === "restaurant" && (
              <Button variant="tertiary" onClick={nextStep}>
                {t(`register.skipStep`)}
              </Button>
            )}
            {role && step === menuList.length && (
              <Button
                variant="success"
                onClick={registerUserAndEntity}
                loading={companyFormLoading || restaurantFormLoading}
              >
                {t(`register.${role}Submit`)}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
