import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import RestaurantRegisterReview from "./RestaurantRegisterReview";

function Review({ role, formData, setOwnerAvatar = () => {} }) {
  const { t } = useTranslation();

  const handleOwnerAvatar = useCallback(
    (avatar) => {
      setOwnerAvatar(avatar);
    },
    [setOwnerAvatar]
  );

  return (
    <div className="register-step">
      <div className="step-header">
        <h2>{t("register.stepReview.title")}</h2>
      </div>
      <div className="row">
        {role === "company" &&
          Object.entries(formData).map(([key, value]) => {
            if (key === "blank") return null;
            else
              return (
                <div
                  className={`mb-4 px-2 column ${
                    key === "message" ? "w-100" : "w-50"
                  } ${key === "role" && "hidden"}`}
                  key={key}
                >
                  <span className="bold">
                    {t(`register.stepReview.company.${key}`)}
                  </span>
                  <span>{value.value ? value.value.label : value}</span>
                </div>
              );
          })}
        {role === "restaurant" && (
          <RestaurantRegisterReview
            form={formData}
            setOwnerAvatar={handleOwnerAvatar}
          />
        )}
      </div>
    </div>
  );
}

export default Review;
