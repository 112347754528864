import React from "react";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import Form from "../../inputs/Form";

function StepFourRestaurant({
  currentForm,
  setOwnerDetails,
  setIsValid = () => {},
}) {
  const { t } = useTranslation();
  const fields = [
    {
      name: "firstname",
      label: t("register.step4.firstname.label"),
      placeholder: t("register.step4.firstname.placeholder"),
      type: "text",
      class: "w-50 pr-2",
      validate: (value) => {
        if (!value.trim()) return t("register.errors.firstname.required");
        if (value.length < 3) return t("register.errors.firstname.min");
        if (value.length > 50) return t("register.errors.firstname.max");
        return "";
      },
    },
    {
      name: "lastname",
      label: t("register.step4.lastname.label"),
      placeholder: t("register.step4.lastname.placeholder"),
      type: "text",
      class: "w-50 pl-2",
      validate: (value) => {
        if (!value.trim()) return t("register.errors.lastname.required");
        if (value.length < 3) return t("register.errors.lastname.min");
        if (value.length > 50) return t("register.errors.lastname.max");
        return "";
      },
    },
    {
      name: "function",
      label: t("register.step4.function.label"),
      placeholder: t("register.step4.function.placeholder"),
      type: "text",
      class: "w-50 pr-2",
      validate: (value) => {
        if (!value.trim()) return t("register.errors.function.required");
        if (value.length > 50) return t("register.errors.function.max");
        return "";
      },
    },
    {
      name: "phone",
      label: t("register.step4.phone.label"),
      placeholder: t("register.step4.phone.placeholder"),
      type: "phone",
      class: "w-50 pl-2",
      validate: (value) => {
        if (!value) return t("register.errors.phone.required");
        if (!isValidPhoneNumber(value))
          return t("register.errors.phone.invalid");
        return "";
      },
    },
    {
      name: "ownerImage",
      label: t("register.step4.picture.label"),
      placeholder: t("register.step4.picture.placeholder"),
      type: "file",
      accept: "image/*",
      class: "w-100",
      info: t("register.step4.picture.info"),
      isUserAvatar: true,
      showPreview: true,
    },
  ];

  const handleOwnerDetails = (data) => {
    setOwnerDetails(data);
  };

  return (
    <div className="register-step">
      <div className="step-header">
        <h2>{t("register.step4.title")}</h2>
        <div className="p3">{t("register.step4.details")}</div>
      </div>
      <Form
        fields={fields}
        currentForm={currentForm}
        setFormDetails={handleOwnerDetails}
        setIsValid={setIsValid}
      />
    </div>
  );
}

export default StepFourRestaurant;
