import { faAngleUp, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import Button from "../buttons/Button";

const Collapse = ({
  title,
  variant = "primary",
  className,
  initialStateOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initialStateOpen);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current)
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
  }, [isOpen]);

  return (
    <div className={`collapse ${className || ""}`}>
      <div className={`collapse-header bg-${variant}`}>
        <div className="p1">{title}</div>
        <Button link onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon
            className="text-light"
            size="xl"
            icon={isOpen ? faAngleUp : faAngleDown}
          />
        </Button>
      </div>
      <div ref={contentRef} className="collapse-body">
        {children}
      </div>
    </div>
  );
};

export default Collapse;
