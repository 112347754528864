import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DishForm from "./DishForm";
import Spinner from "../../../utils/Spinner";
import useApi from "../../../../hooks/useApi";
import {
  creatNewDish,
  updateDish,
  getDish,
  deleteDish,
  getRelatedMenusAndTodaysSpecials,
  switchActivateDish,
} from "../../../../services/dishService";
import Button from "../../../buttons/Button";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useLangNavigate from "../../../../hooks/useLangNavigate";
import ProductAppPreview from "../../../app/ProductAppPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Modal from "../../../utils/Modal";
import useModal from "../../../../hooks/useModal";
import { usePageTitle } from "../../../../hooks/useMeta";
import NavigationContext from "../../../../contexts/NavigationContext";

export const DishView = ({ tKey = "dishView." }) => {
  const { dishId } = useParams();
  usePageTitle(dishId ? "dishView" : "newDish");
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [previewImg, setPreviewImg] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const { setHasChanged } = useContext(NavigationContext);
  const { request: dishCreateRequest } = useApi(creatNewDish);
  const { request: dishGetRequest } = useApi(getDish);
  const { request: dishUpdateRequest } = useApi(updateDish);
  const { request: deleteDishRequest } = useApi(deleteDish);
  const { request: switchActivateRequest } = useApi(switchActivateDish);

  const convertDishToFormData = useCallback(
    (dish) => {
      setPreviewImg(
        dish.imagePath instanceof File
          ? URL.createObjectURL(dish.imagePath)
          : dish.imagePath
      );
      setFormData({
        ...dish,
        finalPrice: dish.discount
          ? dish.price - (dish.price * dish.discount) / 100
          : dish.price,
        dishtype: {
          label: dish.dishtype
            ? t(`utils.dishTypes.${dish.dishtype.label}`)
            : null,
          value: dish.dishtype || null,
        },
        dishcategory: {
          label: dish.dishcategory ? dish.dishcategory.label : null,
          value: dish.dishcategory || null,
        },
        allergies: dish.allergies?.map((a) =>
          !a.value ? { label: t(`utils.allergies.${a.label}`), value: a } : a
        ),
      });
    },
    [setPreviewImg, setFormData, t]
  );

  useEffect(() => {
    setHasChanged(false);
    if (dishId) {
      setIsLoading(true);
      const fetchData = async () => {
        await dishGetRequest(dishId)
          .then((res) => {
            const dish = res.data;
            convertDishToFormData(dish);
            setPreviewImg(dish.imagePath);
          })
          .catch((error) => {
            console.error(error);
            toast.error(t(`${tKey}errors.getDish`));
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      fetchData();
    }
  }, [dishId, dishGetRequest, t, tKey, setHasChanged, convertDishToFormData]);

  const attemptCreateDish = async (e, activate = false) => {
    e.preventDefault();
    await dishCreateRequest({
      ...formData,
      isActive: activate,
      isPublished: true,
      restaurantId,
    })
      .then(() => {
        toast.success(t(`${tKey}success`));
        navigate("dashboard/products");
      })
      .catch((error) => {
        toast.error(t(`${tKey}error`));
        console.log(error);
      });
  };

  const attemptUpdateDish = async (e, activate = false) => {
    e.preventDefault();
    await dishUpdateRequest({
      ...formData,
      isActive: activate,
      isPublished: true,
      dishId,
      restaurantId,
    })
      .then(() => {
        toast.success(t(`${tKey}success`));
        navigate("dashboard/products");
      })
      .catch((error) => {
        toast.error(t(`${tKey}error`));
        console.log(error);
      });
  };

  const attemptSwitchActiveDish = async () => {
    await switchActivateRequest(dishId)
      .then((res) => {
        convertDishToFormData(res.data);
        toast.success(t(`${tKey}success`));
      })
      .catch((error) => {
        toast.error(t(`${tKey}error`));
        console.log(error);
      });
  };

  const attemptDelete = async () => {
    await getRelatedMenusAndTodaysSpecials(dishId).then((res) => {
      const menus = res.data.menus;
      const todaysSpecials = res.data.todaysspecials;
      setModalContent(
        <>
          <h5 className="mt-0">{t(`${tKey}delete.title`)}</h5>
          <p>
            {t(
              `${tKey}delete.${
                menus.length > 0 || todaysSpecials.length > 0
                  ? "textWithRelated"
                  : "text"
              }`,
              { dishName: formData.name }
            )}
          </p>
          {menus.length > 0 && (
            <div>
              {t(`${tKey}delete.menus`, { count: menus.length })}{" "}
              {menus.map((menu, index) => (
                <span key={index}>
                  {menu.name}
                  {index < menus.length - 1 ? ", " : "."}
                </span>
              ))}
            </div>
          )}
          {todaysSpecials.length > 0 && (
            <div>
              {t(`${tKey}delete.todaysSpecials`, {
                count: todaysSpecials.length,
              })}{" "}
              {todaysSpecials.map((todaySpecial, index) => (
                <span key={index}>
                  {todaySpecial.name}
                  {index < todaysSpecials.length - 1 ? ", " : "."}
                </span>
              ))}
            </div>
          )}
        </>
      );
      openModal();
    });
  };

  const deleteCurrentDish = async () => {
    await deleteDishRequest(dishId)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            t(`${tKey}delete.success`, { dishName: formData.name })
          );
          navigate("dashboard/products");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}delete.error`, { dishName: formData.name }));
      })
      .finally(async () => {
        setModalContent(null);
        closeModal();
      });
  };

  const handleFormChange = (data) => {
    setHasChanged(true);
    setFormData(data);
    setPreviewImg(
      data.imagePath instanceof File
        ? URL.createObjectURL(data.imagePath)
        : data.imagePath
    );
  };

  return (
    <>
      <div className="dashboard-content w-50">
        {isLoading ? (
          <Spinner size="3x" variant="danger" label={t("utils.data.loading")} />
        ) : (
          <DishForm
            formData={formData}
            onChange={handleFormChange}
            setIsValid={setIsValid}
            tKey={tKey}
          />
        )}
      </div>
      <div className="dashboard-preview w-50">
        <div className="preview-section">
          <ProductAppPreview
            img={previewImg}
            title={formData?.name !== "" ? formData?.name : null}
            dishCategory={formData?.dishcategory?.value}
          />
        </div>
        <div className="publish-section flex align-center justify-between">
          <div>
            {dishId && (
              <Button variant="white" onClick={attemptDelete}>
                <FontAwesomeIcon size="2x" icon={faTrashAlt} />
              </Button>
            )}
          </div>
          <div className="flex gap-1">
            <Button
              disabled={!isValid}
              onClick={
                dishId
                  ? (e) => attemptUpdateDish(e, formData.isActive)
                  : (e) => attemptCreateDish(e, false)
              }
            >
              {t(`${tKey}${dishId ? "btnUpdate" : "btnPublish"}`)}
            </Button>
            {dishId ? (
              <Button
                variant={formData?.isActive ? "danger" : "success"}
                disabled={!isValid}
                onClick={attemptSwitchActiveDish}
              >
                {t(
                  `${tKey}${
                    formData?.isActive ? "btnDeactivate" : "btnActivate"
                  }`
                )}
              </Button>
            ) : (
              <Button
                variant="success"
                disabled={!isValid}
                onClick={
                  dishId
                    ? (e) => attemptUpdateDish(e, formData.isActive)
                    : (e) => attemptCreateDish(e, true)
                }
              >
                {t(`${tKey}${dishId ? "btnActivate" : "btnPublishActivate"}`)}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteCurrentDish}>
        {modalContent}
      </Modal>
    </>
  );
};

export default DishView;
