import React, { useState, useEffect, useRef, useCallback } from "react";
import Avatar from "boring-avatars";
import { toPng } from "html-to-image";

const UserAvatar = ({
  className = "",
  src = null,
  size = "100",
  fetchRandomAvatar = null,
  onClick = () => {},
}) => {
  const avatarSize = parseInt(size, 10);
  const [seed, setSeed] = useState();
  const avatarRef = useRef(null);

  const generateRandomSeed = useCallback(() => {
    setSeed(new Date().getTime().toString());
  }, []);

  const dataURLtoFile = useCallback((dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    let blob = new Blob([u8arr], { type: mime });
    let file = new File([blob], filename, { type: mime });
    return file;
  }, []);

  useEffect(() => {
    if (!src) generateRandomSeed();
  }, [src, generateRandomSeed]);

  useEffect(() => {
    if (!fetchRandomAvatar) return;
    if (avatarRef.current)
      toPng(avatarRef.current)
        .then((dataUrl) => {
          fetchRandomAvatar(dataURLtoFile(dataUrl, "avatar.png"));
        })
        .catch((err) => {
          console.error("Failed to convert avatar to image:", err);
        });
  }, [src, seed, fetchRandomAvatar, dataURLtoFile]);

  const displayDefaultAvatar = () => {
    return (
      <div ref={avatarRef}>
        <Avatar
          size={avatarSize}
          name={seed}
          variant="beam"
          colors={["#F2461D", "#FFC117", "#1AE170", "#500089", "#000"]}
        />
      </div>
    );
  };

  const displayCustomImage = () => {
    return (
      <img
        src={src instanceof File ? URL.createObjectURL(src) : src}
        className="user-avatar"
        alt="user avatar"
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    );
  };

  return (
    <div className={className} onClick={onClick}>
      {src ? displayCustomImage() : displayDefaultAvatar()}
    </div>
  );
};

export default UserAvatar;
