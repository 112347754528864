import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

const SidebarItem = ({ item, isClickable, count = null, className='' }) => {
    const { t } = useTranslation();
    return (
        <div className={`p2 sidebar-item ${item.isActive ? 'active' : ''} ${className}`}>
            {!isClickable && 
                <span>
                    {`${!!count ? `${count}. ` : ''}${t(`${item.tKey}sidebar`)}`}    
                </span>
            }
            {isClickable && item.onClick ?
                <NavLink onClick={item.onClick} className="sidebar-link">
                    {`${!!count ? `${count}. ` : ''}${t(`${item.tKey}sidebar`)}` }
                </NavLink>
            : (isClickable && item.href !== null && item.href !== undefined &&
                    <NavLink to={isClickable ? item.href : '#'} 
                        target={item.outsideLink ? "_blank" : "_self"}
                        rel={item.outsideLink ? "noopener noreferrer" : undefined} 
                        className="sidebar-link"
                    >
                        <span>{`${!!count ? `${count}. ` : ''}${t(`${item.tKey}sidebar`)}`}</span>
                        {item.outsideLink && <FontAwesomeIcon className='ml-3' icon={faArrowUpRightFromSquare} />}
                    </NavLink>
                )
            }   
        </div>
    );
};

export default SidebarItem;