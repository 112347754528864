import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

const LandingStep = forwardRef(({ title, text, number, isOpen = false, closeOthersSteps = () => {} }, ref) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setIsCollapsed(!isOpen);
    }, [isOpen]);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        if (isCollapsed) {
            closeOthersSteps();
        };
    };

    const closeStep = () => {
        setIsCollapsed(true);
    };

    useImperativeHandle(ref, () => ({
        closeStep
    }));

    return (
        <div className={`landing-step w-100 ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <div className='landing-step-header'>
                <h4 className='text-normal m-0 py-1 flex gap-1' onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                    {number && <span className='circle-around'>
                        {number}
                    </span>}            
                    <span>{title}</span>
                </h4>
            </div>
            <div className={`landing-step-content`}>
                <p className='mt-0 mb-2'>{text}</p>            
            </div>
        </div>
    );
});

export default LandingStep;