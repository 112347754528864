import React from 'react';
import OffoodLogo from '../../components/brand/OffoodLogo';
import useLangNavigate from '../../hooks/useLangNavigate';
import { usePageTitle } from '../../hooks/useMeta';
import { useTranslation } from 'react-i18next';

const LegalMentions = ({ tKey = 'legal.legalMentions.' }) => {
    usePageTitle('legalMentions');
    const navigate = useLangNavigate();
    const { t } = useTranslation();
    return (<>
        <div className='p-2'>
            <OffoodLogo width='100px' onClick={() => navigate('/')} />
        </div>
        <div className='p-4'>
            <h1>{t(`${tKey}title`)}</h1>
            <div dangerouslySetInnerHTML={{ __html: t(`${tKey}content`) }} />
        </div>
    </>);
};

export default LegalMentions;