import React, { useEffect, useState } from 'react';
import Button from '../../../buttons/Button';
import RestaurantAppPreview from '../../../app/RestaurantAppPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-thin-svg-icons';
import { useTranslation } from 'react-i18next';

const Images = ({ data = null, tKey = 'dashboard.settings.images.', onFormDataChange }) => {
  const { t } = useTranslation();
  const [formData] = useState(data);
  const [previewImg, setPreviewImg] = useState(null);

  useEffect(() => {
    if(formData.images && formData.images.find((i) => i.isMainImage))
      setPreviewImg(formData.images.find((i) => i.isMainImage).imagePath);
  }, [formData]);

  const handleImageInput = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.click();
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewImg(reader.result);
        onFormDataChange({ ...formData, imagePath: file });
      };
    };
  };

  return (
    <div className='dashboard-content h-100 flex gap-2'>
      <div className='w-50 bg-white rounded flex column img-settings-container' onClick={handleImageInput}>
        <div className='flex-grow flex align-center justify-center'>
          <FontAwesomeIcon icon={faPlus} size='5x' className='text-light' />
        </div>
        <div className='my-4 flex align-center justify-center'>
          <Button variant='light' size='sm' onClick={handleImageInput}>{t(`${tKey}addImage`)}</Button>
        </div>
      </div>
      <div className='w-50 bg-white rounded p-3 flex align-center justify-center img-settings-container'>
        <RestaurantAppPreview img={previewImg} restaurantType={formData.restauranttype} title={formData?.name !== '' ? formData?.name : null} />
      </div>
    </div>
  );
};

export default Images;
