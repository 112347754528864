import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import SidebarItem from "./SidebarItem";
import OffoodLogo from "../brand/OffoodLogo";
import OffoodIcon from "../brand/OffoodIcon";
import MobileSidebar from "./MobileSidebar";
import { useAuth } from "../../hooks/useAuth";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../../hooks/useLangNavigate";
import Alert from "../utils/Alert";

function Sidebar({
  menuList,
  isClickable = false,
  count = false,
  title = null,
  description = null,
  showLogout = true,
}) {
  const navigate = useLangNavigate();
  const { isGtLaptop, isLtLaptop } = useBreakpoint();
  const { logout, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const controlledRestaurantName = useSelector(
    (state) => state.admin.controlledRestaurantName
  );
  const controlledCompanyName = useSelector(
    (state) => state.admin.controlledCompanyName
  );
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  return (
    <div id="sidebar">
      <div className="sidebar-header">
        {isGtLaptop() ? (
          <OffoodLogo width="100px" onClick={() => navigate("/")} />
        ) : (
          <OffoodIcon width="50px" onClick={() => navigate("/")} />
        )}
      </div>
      {controlledRestaurantName && (
        <Alert className="mb-2">
          <span
            dangerouslySetInnerHTML={{
              __html: t("dashboard.admin.controlledRestaurant", {
                name: controlledRestaurantName,
              }),
            }}
          />
        </Alert>
      )}
      {controlledCompanyName && (
        <Alert className="mb-2">
          <span
            dangerouslySetInnerHTML={{
              __html: t("dashboard.admin.controlledCompany", {
                name: controlledCompanyName,
              }),
            }}
          />
        </Alert>
      )}
      {isAuthenticated && (
        <FontAwesomeIcon
          icon={faBars}
          className="sidebar-burger"
          onClick={() => setMobileSidebarOpen(true)}
        />
      )}
      {(title || description) && (
        <div className="sidebar-text-span">
          {title && <h3 className="sidebar-title">{title}</h3>}
          {description && (
            <div className="p3 sidebar-description text-light">
              {description}
            </div>
          )}
        </div>
      )}
      <div className="sidebar-list">
        {menuList
          .filter((m) => !m.hide)
          .map((item, index) => (
            <SidebarItem
              key={index}
              item={item}
              isClickable={isClickable}
              count={count && index + 1}
            />
          ))}
        {isAuthenticated && showLogout && (
          <SidebarItem
            item={{ tKey: "dashboard.logout.", onClick: logout }}
            isClickable={isClickable}
          />
        )}
      </div>
      {isLtLaptop() && (
        <MobileSidebar
          className={`${isMobileSidebarOpen ? "open" : ""}`}
          menuList={menuList}
          onClose={() => setMobileSidebarOpen(false)}
          isClickable={isClickable}
        />
      )}
    </div>
  );
}

export default Sidebar;
