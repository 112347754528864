import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';
import TimeRangeInput from './TimeRangeInput';
import Button from '../buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import FlipMove from 'react-flip-move';
import { useTranslation } from 'react-i18next';

const ScheduleForm = ({ data = null, onScheduleChange, hasEvening = true }) => {
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState(hasEvening
    ? [{ day: 1, noon: { startAt: null, endAt: null }, evening: { startAt: null, endAt: null } }]
    : [{ day: 1, startAt: null, endAt: null }]
  );

  const getNextAvailableDay = useCallback((schedule, day) => {
    if (schedule.some(s => s.day === day))
      return getNextAvailableDay(schedule, day % 7 + 1);
    return day;
  }, []);

  const handleTimeChange = (index, name, value) => {
    setSchedule(prevSchedule => {
      const newSchedule = [...prevSchedule];
      if(hasEvening)
        newSchedule[index][name] = value;
      else
        newSchedule[index] = { ...newSchedule[index], ...value };
      return newSchedule;
    });
  };

  const handleDayChange = (index, selectedDay) => {
    setSchedule(prevSchedule => {
      const newSchedule = [...prevSchedule];
      newSchedule[index].day = selectedDay.value;
      return newSchedule;
    });
  };

  const handleAddDay = () => {
    setSchedule(prevSchedule => {
      const nextDay = getNextAvailableDay(prevSchedule, 1);
      if(hasEvening)
        return [...prevSchedule, { day: nextDay, noon: { startAt: null, endAt: null }, evening: { startAt: null, endAt: null } }];
      else
        return [...prevSchedule, { day: nextDay, startAt: null, endAt: null }];
    });
  };

  const handleRemoveDay = (index) => {
    setSchedule(prevSchedule => {
      const newSchedule = [...prevSchedule];
      newSchedule.splice(index, 1);
      return newSchedule;
    });
  };

  const handleDuplicateDay = (index) => {
    setSchedule(prevSchedule => {
      const newSchedule = [...prevSchedule];
      const nextDay = getNextAvailableDay(prevSchedule, prevSchedule[index].day + 1);
      const duplicatedDay = { ...prevSchedule[index], day: nextDay };
      newSchedule.push(duplicatedDay);
      return newSchedule;
    });
  };

  const daysOfWeek = [
    { label: t('utils.days.monday'), value: 1 },
    { label: t('utils.days.tuesday'), value: 2 },
    { label: t('utils.days.wednesday'), value: 3 },
    { label: t('utils.days.thursday'), value: 4 },
    { label: t('utils.days.friday'), value: 5 },
    { label: t('utils.days.saturday'), value: 6 },
    { label: t('utils.days.sunday'), value: 7 },
  ];

  useEffect(() => { 
    if (data) setSchedule(data); 
  }, [data, setSchedule]);

  useEffect(() => {
    const filteredSchedule = schedule.filter(s => s.day && (hasEvening ? s.noon.startAt && s.noon.endAt && s.evening.startAt && s.evening.endAt : s.startAt && s.endAt));
    onScheduleChange(filteredSchedule);
  }, [schedule, hasEvening, onScheduleChange]);

  return (<>
    <FlipMove duration={300}>
      {schedule
        .map((day, index) => ({ index, day }))
        .sort((a, b) => a.day.day - b.day.day)
        .map(({ index, day }) => {
          const availableDays = daysOfWeek.filter(d => !schedule.some(s => s.day === d.value));
          return (
            <div className='flex mb-4 gap-1 schedules' key={index}>
              <div className='w-30 schedule-days'>
                <Select components={{ DropdownIndicator }}                
                  className={`react-custom-select`}
                  classNamePrefix="react-select"
                  options={availableDays}
                  value={daysOfWeek.find(d => d.value === day.day)}
                  onChange={(selectedDay) => handleDayChange(index, selectedDay)}
                />
              </div>
              <div className='flex-grow flex column gap-1 schedule-times'>
                <TimeRangeInput classNameInput='small-input"'
                  onChange={(value) => handleTimeChange(index, 'noon', value)}
                  value={hasEvening ? day.noon : day}
                  step={15}
                  minHour={10}
                  maxHour={14.5}
                />
                {hasEvening &&
                  <TimeRangeInput onChange={(value) => handleTimeChange(index, 'evening', value)}
                    value={day.evening}
                    step={15}
                    minHour={16}
                    maxHour={23}
                    isStartClearable={true}
                    isEndClearable={true}
                  />
                }
              </div>  
              <div className='w-10 flex column gap-1 schedule-buttons'>
                {schedule.length > 1 &&
                  <Button variant='danger' size='xs' rounded onClick={() => handleRemoveDay(index)}>
                    <FontAwesomeIcon icon={faMinus} />  
                  </Button>
                }
                {schedule.length < 7 &&
                  <Button variant='info' size='xs' rounded onClick={() => handleDuplicateDay(index)}>
                    <FontAwesomeIcon icon={faCopy} />  
                  </Button>
                }
              </div>
            </div>
          );
      })}
    </FlipMove>
    {schedule.length < 7 &&
      <Button variant='primary' size='xs' rounded onClick={() => handleAddDay()}>
        <FontAwesomeIcon icon={faPlus} />  
      </Button>
    }
  </>);
};

export default ScheduleForm;