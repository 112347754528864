import React from 'react';
import OffoodLogo from '../../components/brand/OffoodLogo';
import useLangNavigate from '../../hooks/useLangNavigate';
import { usePageTitle } from '../../hooks/useMeta';
import { useTranslation } from 'react-i18next';

const ConsentAndCookies = () => {
    usePageTitle('consentAndCookies');
    const navigate = useLangNavigate();
    const { t } = useTranslation();
    return (<>
        <div className='p-2'>
            <OffoodLogo width='100px' onClick={() => navigate('/')} />
        </div>
        <iframe title='Consent And Cookes' src={`https://www.iubenda.com/privacy-policy/${t('legal.privacy')}`} style={{ width: '100%', height: '100vh' }} />);
    </>);
};

export default ConsentAndCookies;