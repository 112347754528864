import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../redux/slices/userSlice";
import { stopAllControlling } from "../redux/slices/adminSlice";
import { postAttemptLogout } from "../services/authService";

export function useAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isAuthenticated = !!user.token;
  const userRoles = useSelector((state) => state.user.roles);

  const hasRole = (roles) => {
    return userRoles?.some((role) => roles.includes(role.label));
  };

  const isOffoodAdmin = hasRole(["admin"]);
  const isRestaurantAdmin = hasRole(["restaurantAdmin"]);
  const isCompanyAdmin = hasRole(["companyAdmin"]);
  const belongsToRestaurant = hasRole([
    "restaurantAdmin",
    "restaurantEmployee",
  ]);
  const belongsToCompany = hasRole(["companyAdmin", "companyEmployee"]);

  const logout = () => {
    postAttemptLogout();
    localStorage.removeItem("token");
    dispatch(clearUser());
    dispatch(stopAllControlling());
    navigate("/");
  };

  return {
    isAuthenticated,
    hasRole,
    isOffoodAdmin,
    isCompanyAdmin,
    isRestaurantAdmin,
    belongsToRestaurant,
    belongsToCompany,
    roles: userRoles,
    user,
    logout,
  };
}
