import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateLang } from '../../redux/slices/userSlice';
import useApi from "../../hooks/useApi";
import { putUserLanguage } from '../../services/userService';

const LangButton = ({ tKey = 'utils.lang.', noFlag = false, placeholder = null, block = false }) => {
    const { i18n, t } = useTranslation();
    const [languages, setLanguages] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userId = useSelector(state => state.user.userId);
    const {
        request: updateUserLanguage,
    } = useApi(putUserLanguage);

    useEffect(() => {
        const availableLanguages = Object.keys(i18n.options.resources);
        const languageOptions = availableLanguages.map(lang => {
            return {
                value: lang,
                label: t(`${tKey}${lang}`),
            };
        });

        const fetchFlags = async () => {
            const updatedLanguages = await Promise.all(languageOptions.map(async language => {
                return { ...language, flagUrl: `https://flagcdn.com/w20/${language.value === 'en' ? 'us' : language.value}.webp` };  
            }));
            setLanguages(updatedLanguages);
        };

        fetchFlags();
    }, [i18n, t, tKey]);

    const CustomPlaceholder = (props) => (
        <components.Placeholder {...props}>
            <div className="flex justify-end">
                {placeholder}
            </div>
        </components.Placeholder>
    );

    const formatOptionLabel = ({ label, flagUrl }) => (
        <div className='flex'>
            {!noFlag && <img className='mr-2' src={flagUrl} alt="" />}
            {label}
        </div>
    );

    const handleChange = option => {
        const selectedLanguage = option.value;
        if(userId)
            updateUserLanguage(userId, selectedLanguage).then((res) => {
                dispatch(updateLang({ language: res.data }));
            });
        i18n.changeLanguage(selectedLanguage);
        const pathWithoutLang = location.pathname.split('/').slice(2).join('/');
        const newPath = `/${selectedLanguage}/${pathWithoutLang}`;
        navigate(newPath);
    };

    return (<div className='flex lang-button'>
        <Select
            options={languages}
            value={languages.find(opt => opt.value === i18n.language)}
            onChange={handleChange}
            formatOptionLabel={formatOptionLabel}            
            components={placeholder ? { SingleValue: CustomPlaceholder } : {}}
            className={`react-custom-select dashboard-select ${block ? 'flex-grow' : ''}`}
            classNamePrefix="react-select"
            isSearchable={false}
        />
    </div>);
};

export default LangButton;
