import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SidebarItem from "./SidebarItem";
import LangButton from "../buttons/LangButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import Button from "../buttons/Button";
import NotificationsList from "../notifications/NotificationsList";
import RestaurantAppPreview from "../../components/app/RestaurantAppPreview";
import { useAuth } from "../../hooks/useAuth";
import useLangNavigate from "../../hooks/useLangNavigate";
import useWebSocket from "../../hooks/useWebSocket";
import useApi from "../../hooks/useApi";
import { getRestaurant } from "../../services/restaurantService";
import { useSelector, useDispatch } from "react-redux";
import {
  stopControllingRestaurant,
  stopControllingCompany,
} from "../../redux/slices/adminSlice";
import { setRestaurantId } from "../../redux/slices/userSlice";

const MobileSidebar = ({ menuList, onClose, isClickable, className }) => {
  const { logout, isAuthenticated, isRestaurantAdmin, isOffoodAdmin } =
    useAuth();
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showRestaurantMenu, setShowRestaurantMenu] = useState(false);
  const [isOnOverview, setIsOnOverview] = useState(false);
  const [isRestaurantActive, setIsRestaurantActive] = useState(false);
  const [isCateringActive, setIsCateringActive] = useState(false);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const restaurants = useSelector((state) => state.user.restaurants);
  const controlledRestaurantId = useSelector(
    (state) => state.admin.controlledRestaurantId
  );
  const controlledCompanyId = useSelector(
    (state) => state.admin.controlledCompanyId
  );
  const dispatch = useDispatch();
  const { request: fetchRestaurant } = useApi(getRestaurant);
  const user = useSelector((state) => state.user);
  const { notifications, setNotifications } = useWebSocket(
    "notifications",
    user.userId
  );

  useEffect(() => {
    setIsOnOverview(window.location.pathname.endsWith("/dashboard"));
  }, []);

  useEffect(() => {
    if (isOnOverview && isRestaurantAdmin && !isOffoodAdmin && restaurantId) {
      fetchRestaurant(restaurantId).then((response) => {
        const restaurant = response.data;
        setIsRestaurantActive(restaurant.isActive);
        setIsCateringActive(restaurant.isCateringActive);
      });
    }
  }, [
    isRestaurantAdmin,
    isOnOverview,
    isRestaurantActive,
    isCateringActive,
    isOffoodAdmin,
    restaurantId,
    fetchRestaurant,
  ]);

  const handleStopControllingRestaurant = () => {
    dispatch(stopControllingRestaurant());
    navigate("dashboard");
  };

  const handleStopControllingCompany = () => {
    dispatch(stopControllingCompany());
    navigate("dashboard");
  };

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
    if (showNotifications) setNotifications(0);
  };

  const handleSelectRestaurant = (restaurantId) => {
    dispatch(setRestaurantId(restaurantId));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className={`mobile-sidebar-overlay ${className || ""}`}>
      {showNotifications && (
        <NotificationsList
          userId={user.userId}
          closeNotifications={() => setShowNotifications(false)}
        />
      )}
      {showRestaurantMenu && (
        <div id="restaurantmenu" className="flex column gap-1">
          <p className="bold">
            {restaurants.find((r) => r.restaurantId === restaurantId).name}
          </p>
          {restaurants
            .filter((r) => r.restaurantId !== restaurantId)
            .map((restaurant) => (
              <Button
                variant="tertiary"
                className="p-4 justify-start gap-2"
                block
                onClick={() => handleSelectRestaurant(restaurant.restaurantId)}
                key={restaurant.restaurantId}
              >
                <RestaurantAppPreview
                  size="45"
                  img={restaurant.images?.[0]?.fullImagePath}
                  restaurantType={restaurant.restauranttype}
                />
                <span>{restaurant.name}</span>
              </Button>
            ))}
          <Button
            size="sm"
            block
            onClick={() => navigate("register", { state: { initialStep: 2 } })}
          >
            <span>{t("dashboard.restaurant.addRestaurant")}</span>
          </Button>
        </div>
      )}
      <div className="mobile-sidebar" onClick={(e) => e.stopPropagation()}>
        <div className="mobile-sidebar-header">
          <button onClick={onClose} className="close-btn">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="flex gap-1">
            {isRestaurantAdmin && restaurantId && !controlledRestaurantId && (
              <div
                className="px-1 flex gap-1 align-center bg-pastel-light very-rounded cursor-pointer"
                onClick={() => setShowRestaurantMenu(!showRestaurantMenu)}
              >
                <RestaurantAppPreview
                  isAvatar
                  size="40"
                  img={
                    restaurants.find((r) => r.restaurantId === restaurantId)
                      .images?.[0]?.fullImagePath
                  }
                  restaurantType={
                    restaurants.find((r) => r.restaurantId === restaurantId)
                      .restauranttype
                  }
                />
                <span>
                  {restaurants.find((r) => r.restaurantId === restaurantId).name
                    .length > 12
                    ? `${restaurants
                        .find((r) => r.restaurantId === restaurantId)
                        .name.slice(0, 9)}...`
                    : restaurants.find((r) => r.restaurantId === restaurantId)
                        .name}
                </span>
              </div>
            )}
            <Button variant="light" rounded onClick={handleShowNotifications}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon color="black" size="xl" icon={faBell} />
                {notifications > 0 && (
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="xs"
                    color="red"
                    transform="right-10 up-10"
                  />
                )}
              </span>
            </Button>
          </div>
        </div>
        <div className="mobile-sidebar-list">
          {menuList.map((item, index) => (
            <SidebarItem
              className="mobile-sidebar-item"
              key={index}
              item={item}
              isClickable={isClickable}
            />
          ))}
        </div>
        <div className="mobile-sidebar-footer">
          {controlledRestaurantId && isOffoodAdmin && (
            <Button variant="primary" onClick={handleStopControllingRestaurant}>
              {t("dashboard.admin.clearControlledRestaurant")}
            </Button>
          )}
          {controlledCompanyId && isOffoodAdmin && (
            <Button variant="primary" onClick={handleStopControllingCompany}>
              {t("dashboard.admin.clearControlledCompany")}
            </Button>
          )}
          <LangButton block noFlag={false} />
          {isAuthenticated && (
            <SidebarItem
              className="mobile-sidebar-item"
              item={{ tKey: "dashboard.logout.", onClick: logout }}
              isClickable={isClickable}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
