import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { usePageTitle } from "../../../hooks/useMeta";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../../redux/slices/userSlice";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import Tabs from "../../tabs/Tabs";
import Profile from "../settings/restaurant/Profile";
import Images from "../settings/restaurant/Images";
//import Delivery from '../settings/restaurant/Delivery';
import Catering from "../settings/restaurant/Catering";
import Bank from "../settings/restaurant/Bank";
import Manager from "../settings/restaurant/Manager";
import Connection from "../settings/Connection";
import DangerZone from "../settings/restaurant/DangerZone";
import {
  getRestaurantSettings,
  updateRestaurantSettings,
} from "../../../services/restaurantService";
import { updateRestaurantSettingsByAdmin } from "../../../services/admin/restaurantService";
import {
  getRestaurantUserDetail,
  updateRestaurantEmployee,
} from "../../../services/userService";
import { putAttemptChangePassword } from "../../../services/authService";
import SettingsContext from "../../../contexts/SettingsContext";
import useBreakpoint from "../../../hooks/useBreakpoint";
import Button from "../../buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-light-svg-icons";

export const RestaurantSettings = ({ tKey = "dashboard.settings." }) => {
  usePageTitle("restaurantSettings");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [manager, setManager] = useState({});
  const [restaurantSettings, setRestaurantSettings] = useState({});
  const [tempManagerSettings, setTempManagerSettings] = useState({});
  const [tempRestaurantSettings, setTempRestaurantSettings] = useState({});
  const [tempPassword, setTempPassword] = useState({});
  const { request: getSettingsRequest } = useApi(getRestaurantSettings);
  const { request: getManagerRequest } = useApi(getRestaurantUserDetail);
  const { request: updateSettingsRequest } = useApi(updateRestaurantSettings);
  const { request: updateSettingsByAdminRequest } = useApi(
    updateRestaurantSettingsByAdmin
  );
  const { request: updateManagerRequest } = useApi(updateRestaurantEmployee);
  const { request: attemptChangePasswordRequest } = useApi(
    putAttemptChangePassword
  );
  const user = useSelector((state) => state.user);
  const isControllingEntity = useSelector(
    (state) => state.admin.isControllingEntity
  );
  const {
    activeTab,
    setActiveTab,
    setIsValid,
    setApplyChanges,
    setHideSettingsButton,
    isValid,
  } = useContext(SettingsContext);
  const { isLtLaptop } = useBreakpoint();

  const handleFormDataChange = useCallback(
    (data) => {
      setIsValid(!!data);
      setTempRestaurantSettings((old) => {
        return { ...old, ...data };
      });
    },
    [setIsValid, setTempRestaurantSettings]
  );

  const handleManagerDataChange = useCallback(
    (data) => {
      setIsValid(!!data);
      setTempManagerSettings((old) => {
        return { ...old, ...data };
      });
    },
    [setIsValid, setTempManagerSettings]
  );

  const handlePasswordChange = useCallback(
    (data) => {
      setIsValid(!!data);
      setTempPassword(data);
    },
    [setIsValid, setTempPassword]
  );

  const tabs = useMemo(
    () => [
      {
        name: "profile",
        content: () => (
          <Profile
            data={restaurantSettings}
            onFormDataChange={handleFormDataChange}
          />
        ),
      },
      {
        name: "images",
        content: () => (
          <Images
            data={restaurantSettings}
            onFormDataChange={handleFormDataChange}
          />
        ),
      },
      /*
    {
      name: 'delivery',
      content: () => <Delivery data={restaurantSettings} onFormDataChange={handleFormDataChange} />,
    },
    */
      {
        name: "catering",
        hide: !restaurantSettings.consumptionmodes?.find(
          (mode) => mode.consumptionModeId === 5
        ),
        content: () => (
          <Catering
            data={restaurantSettings}
            onFormDataChange={handleFormDataChange}
          />
        ),
      },
      {
        name: "bank",
        content: () => <Bank restaurantId={user.restaurantId} />,
      },
      {
        name: "manager",
        content: () => (
          <Manager data={manager} onFormDataChange={handleManagerDataChange} />
        ),
      },
      {
        name: "connection",
        content: () => <Connection onFormDataChange={handlePasswordChange} />,
      },
      {
        name: "dangerZone",
        content: () => <DangerZone />,
        buttonVariant: "danger",
      },
    ],
    [
      restaurantSettings,
      handleFormDataChange,
      handleManagerDataChange,
      handlePasswordChange,
      manager,
      user.restaurantId,
    ]
  );

  const fetchSettingsRestaurant = useCallback(
    async (restaurantId) => {
      await getSettingsRequest(restaurantId)
        .then((response) => {
          setRestaurantSettings(response.data);
          setManager(response.data.manager[0] || null);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getSettingsRequest, setRestaurantSettings, setManager]
  );

  const fetchSettingsManager = useCallback(
    async (userId) => {
      await getManagerRequest(userId)
        .then((response) => {
          dispatch(updateUser(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getManagerRequest, dispatch]
  );

  const attemptUpdateProfile = useCallback(async () => {
    await updateSettingsRequest(user.restaurantId, tempRestaurantSettings)
      .then(async () => {
        toast.success(t(`${tKey}success`));
        await fetchSettingsRestaurant(user.restaurantId);
      })
      .catch((error) => {
        toast.error(t(`${tKey}error`));
        console.log(error);
      });
  }, [
    updateSettingsRequest,
    user.restaurantId,
    tempRestaurantSettings,
    t,
    tKey,
    fetchSettingsRestaurant,
  ]);

  const attemptUpdateProfileByAdmin = useCallback(async () => {
    await updateSettingsByAdminRequest(
      user.restaurantId,
      tempRestaurantSettings
    ).then(async () => {
      toast.success(t(`${tKey}success`));
      await fetchSettingsRestaurant(user.restaurantId);
    });
  }, [
    updateSettingsByAdminRequest,
    user.restaurantId,
    tempRestaurantSettings,
    t,
    tKey,
    fetchSettingsRestaurant,
  ]);

  const attemptUpdateManager = useCallback(async () => {
    if (manager)
      await updateManagerRequest(manager.userId, tempManagerSettings).then(
        async () => {
          toast.success(t(`${tKey}success`));
          await fetchSettingsManager(manager.userId);
        }
      );
  }, [
    updateManagerRequest,
    manager,
    tempManagerSettings,
    t,
    tKey,
    fetchSettingsManager,
  ]);

  const attemptChangePassword = useCallback(async () => {
    await attemptChangePasswordRequest(user.userId, tempPassword).then(() => {
      toast.success(t(`${tKey}success`));
    });
  }, [attemptChangePasswordRequest, user.userId, tempPassword, t, tKey]);

  const applyChanges = useCallback(() => {
    switch (activeTab) {
      case "bank":
        return null;
      case "manager":
        return manager ? attemptUpdateManager() : null;
      case "connection":
        return attemptChangePassword();
      case "dangerZone":
        return;
      default:
        return isControllingEntity
          ? attemptUpdateProfileByAdmin()
          : attemptUpdateProfile();
    }
  }, [
    activeTab,
    manager,
    attemptChangePassword,
    attemptUpdateManager,
    attemptUpdateProfile,
    attemptUpdateProfileByAdmin,
    isControllingEntity,
  ]);

  const setActiveTabWrapper = (tab) => {
    setHideSettingsButton(tab === "dangerZone");
    setActiveTab(tab);
  };

  useEffect(() => {
    return () => {
      setActiveTab("");
    };
  }, [setActiveTab]);

  useEffect(() => {
    fetchSettingsRestaurant(user.restaurantId);
  }, [user.restaurantId, fetchSettingsRestaurant]);

  useEffect(() => {
    setApplyChanges(() => applyChanges);
  }, [applyChanges, setApplyChanges]);

  return (
    <>
      <div id="settings" className="dashboard-content">
        <Tabs
          preventSwitchTab={true}
          tKey={tKey}
          tKeySuffix=".tab"
          tabs={tabs}
          onTabChange={setActiveTabWrapper}
          contentClass="flex gap-1 settings-content"
        ></Tabs>
        {isLtLaptop() && (
          <Button
            onClick={applyChanges}
            floating
            variant="success"
            disabled={!isValid}
            className="flex gap-1"
          >
            <FontAwesomeIcon size="2x" icon={faSave} />
            <span>{t(`utils.common.save`)}</span>
          </Button>
        )}
      </div>
    </>
  );
};

export default RestaurantSettings;
