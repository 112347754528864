import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../../inputs/Form';
import PromotionForm from '../../../inputs/PromotionForm';
import ScheduleForm from '../../../inputs/ScheduleForm';
import { formatSchedules, formatSwissPrice } from '../../../../utils/formatting';

const Catering = ({ tKey = 'dashboard.settings.catering.', data = null, onFormDataChange }) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([])
  const [formData, setFormData] = useState({});
  const [formattedSchedules, setFormattedSchedules] = useState(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setFormData(data);
      setFormattedSchedules(formatSchedules(data.cateringschedules, false));
    };
  }, [data]);

  useEffect(() => {
    setFields([
      {
        name: 'minimumDaysForCatering',
        label: t(`${tKey}minimumDaysForCatering.label`),
        placeholder: t(`${tKey}minimumDaysForCatering.placeholder`),
        type: 'number',
        class: 'w-100',
        validate: (value) => {
          if (value < 0) return t(`${tKey}errors.minimumDaysForCatering.invalid`);
          return '';
        },
      },
      {
        name: 'minimumPeopleForCatering',
        label: t(`${tKey}minimumPeopleForCatering.label`),
        placeholder: t(`${tKey}minimumPeopleForCatering.placeholder`),
        type: 'number',
        class: 'w-50 pr-1',
        validate: (value) => {
          if (value < 0) return t(`${tKey}errors.minimumPeopleForCatering.invalid`);
          return '';
        },
      },
      {
        name: 'maximumPeopleForCatering',
        label: t(`${tKey}maximumPeopleForCatering.label`),
        placeholder: t(`${tKey}maximumPeopleForCatering.placeholder`),
        type: 'number',
        class: 'w-50 pl-1',
        validate: (value) => {
          if (value < 0) return t(`${tKey}errors.maximumPeopleForCatering.invalid`);
          return '';
        },
      },    
      {
        name: 'baseDistanceCatering',
        label: t(`${tKey}baseDistanceCatering.label`),
        placeholder: t(`${tKey}baseDistanceCatering.placeholder`),
        type: 'number',
        class: 'w-100',
        inputContainerClassName: 'input-km',
        validate: (value) => {
          if (!value) return t(`${tKey}errors.baseDistanceCatering.required`);
          if (value < 1) return t(`${tKey}errors.baseDistanceCatering.invalid`);
          if (value > 100) return t(`${tKey}errors.baseDistanceCatering.max`);
          return '';
        },
      },  
      {
        name: 'basePriceCatering',
        label: t(`${tKey}basePriceCatering.label`),
        placeholder: t(`${tKey}basePriceCatering.placeholder`),
        type: 'number',
        class: 'w-50 pr-1',
        inputContainerClassName: 'input-chf',
        validate: (value) => {
          if (!value) return t(`${tKey}errors.basePriceCatering.required`);
          if (value < 0) return t(`${tKey}errors.basePriceCatering.invalid`);
          if (value > 100) return t(`${tKey}errors.basePriceCatering.max`);
          return '';
        },
        onBlur: (event, updateFormState, field) => {
          const formattedValue = formatSwissPrice(event.target.value);     
          updateFormState(field, formattedValue);    
        },
      },
      {
        name: 'pricePerKmCatering',
        label: t(`${tKey}pricePerKmCatering.label`), 
        placeholder: t(`${tKey}pricePerKmCatering.placeholder`),
        type: 'number',
        class: 'w-50 pl-1',
        inputContainerClassName: 'input-chf',
        validate: (value) => {
          if (!value) return t(`${tKey}errors.pricePerKmCatering.required`);
          if (value < 0) return t(`${tKey}errors.pricePerKmCatering.invalid`);
          if (value > 100) return t(`${tKey}errors.pricePerKmCatering.max`);
          return '';
        },
        onBlur: (event, updateFormState, field) => {
          const formattedValue = formatSwissPrice(event.target.value);     
          updateFormState(field, formattedValue);    
        },
      }   
    ]);
  }, [t, tKey]);

  useEffect(() => {
    onFormDataChange(isValid ? formData : null);
  }, [formData, isValid, onFormDataChange]);

  const setFormSchedules = useCallback((schedules) => {
    setFormData(formData => ({ ...formData, cateringschedules: schedules }));
  }, []);

  const setFormPromotions = useCallback((promotions) => {
    setFormData(formData => ({ ...formData, cateringpromotions: promotions }));
  }, []);
  
  return (
    <div className='dashboard-content flex gap-2'>
      <div className='w-50 bg-white rounded p-3'>
        <Form formGroupClass='mb-4' fields={fields} currentForm={formData} setFormDetails={setFormData} setIsValid={setIsValid} />
        <PromotionForm data={formData.cateringpromotions} onPromotionsChange={setFormPromotions} />
      </div>
      <div className='w-50 bg-white rounded p-3 table-container'>
        <div className='mb-2'>{ t(`${tKey}schedule.label`) }</div>
        <ScheduleForm data={formattedSchedules || null} onScheduleChange={setFormSchedules} hasEvening={false} />
      </div>
    </div>
  );
};

export default Catering;
