import { formatPhoneNumberIntl } from "react-phone-number-input";

export const isValidEmail = (email) => {
  return !/\S+@\S+\.\S+/.test(email);
};

export const formatSwissPrice = (value, withCurrency = false) => {
  let number = Number(value);
  if (isNaN(number)) return null;
  number = Math.round(number * 20) / 20;
  return `${number.toFixed(2)}${withCurrency ? " CHF" : ""}`;
};

export const groupDishesByCategories = (dishes) => {
  const groupedDishes = dishes.reduce((grouped, dish) => {
    const categoryLabel = dish.dishcategory
      ? dish.dishcategory.label
      : "no_category";
    (grouped[categoryLabel] = grouped[categoryLabel] || []).push(dish);
    return grouped;
  }, {});
  Object.keys(groupedDishes).forEach((key) => {
    groupedDishes[key] = groupedDishes[key].sort((a, b) => a.order - b.order);
  });
  return groupedDishes;
};

export const groupDishesByTypes = (dishes) => {
  const groupedDishes = dishes.reduce((grouped, dish) => {
    const typeLabel = dish.dishtype ? dish.dishtype.label : "no_type";
    (grouped[typeLabel] = grouped[typeLabel] || []).push(dish);
    return grouped;
  }, {});
  Object.keys(groupedDishes).forEach((key) => {
    groupedDishes[key] = groupedDishes[key].sort((a, b) => a.order - b.order);
  });
  return groupedDishes;
};

export const formatDate = (
  dateString,
  includeTime = true,
  locale = "fr-CH",
  options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
) => {
  const date = new Date(dateString);
  if (!includeTime) {
    delete options.hour;
    delete options.minute;
  }
  return date.toLocaleString(locale, options);
};

export const formatMoney = (
  value = 0,
  locale = "fr-CH",
  options = { style: "currency", currency: "CHF" }
) => {
  return value.toLocaleString(locale, options);
};

export const formatSchedules = (schedules, hasEvening = true) => {
  if (!schedules) return null;
  if (hasEvening)
    return schedules.map((s) => {
      return {
        day: s.day,
        noon: { startAt: s.noonStartAt, endAt: s.noonEndAt },
        evening: { startAt: s.eveningStartAt, endAt: s.eveningEndAt },
      };
    });
  return schedules.map((s) => {
    return {
      day: s.day,
      startAt: s.startAt,
      endAt: s.endAt,
    };
  });
};

export const formatPhoneNumber = (phoneNumber) => {
  return formatPhoneNumberIntl(phoneNumber);
};
