import { useState, useEffect } from "react";

function useResponsive() {
  const breakpoints = {
    phone: 480,
    tablet: 768,
    laptop: 1024,
    smallScreen: 1200,
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isGtPhone = () => width > breakpoints.phone;
  const isLtPhone = () => width <= breakpoints.phone;
  const isGtTablet = () => width > breakpoints.tablet;
  const isLtTablet = () => width <= breakpoints.tablet;
  const isGtLaptop = () => width > breakpoints.laptop;
  const isLtLaptop = () => width <= breakpoints.laptop;
  const isGtSmallScreen = () => width > breakpoints.smallScreen;
  const isLtSmallScreen = () => width <= breakpoints.smallScreen;

  return {
    isGtPhone,
    isLtPhone,
    isGtTablet,
    isLtTablet,
    isGtLaptop,
    isLtLaptop,
    isGtSmallScreen,
    isLtSmallScreen,
  };
}

export default useResponsive;
