import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import Button from "./Button";

let lastOpenedDropdown = null; // Global variable to track the last opened dropdown

const DropdownButton = ({
  actions,
  children,
  btnProps = {
    size: "sm",
    variant: "transparent",
  },
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setShowDropdown(false);
  };

  const toggleDropdown = useCallback(() => {
    if (lastOpenedDropdown && lastOpenedDropdown !== setShowDropdown)
      lastOpenedDropdown(false);
    setShowDropdown(!showDropdown);
    lastOpenedDropdown = setShowDropdown;
  }, [showDropdown]);

  const updateDropdownPosition = () => {
    if (!dropdownMenuRef.current || !dropdownRef.current) return;

    const buttonRect = dropdownRef.current.getBoundingClientRect();
    const menuHeight = dropdownMenuRef.current.offsetHeight;

    // Space available below the button
    const spaceBelow = window.innerHeight - buttonRect.bottom;

    let topPosition;
    if (spaceBelow < menuHeight)
      topPosition = buttonRect.top - menuHeight + window.scrollY + 5;
    else topPosition = buttonRect.bottom + window.scrollY - 5;

    // Calculate the new left position
    const leftPosition = buttonRect.left + window.scrollX;

    dropdownMenuRef.current.style.position = "absolute";
    dropdownMenuRef.current.style.top = `${topPosition}px`;
    dropdownMenuRef.current.style.left = `${leftPosition - 160}px`;
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("scroll", toggleDropdown, true);
      updateDropdownPosition();
    } else {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", toggleDropdown, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", toggleDropdown, true);
    };
  }, [showDropdown, toggleDropdown]);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown-container ${showDropdown ? "dropdown-opened" : ""}`}
      onClick={(event) => event.stopPropagation()}
    >
      <Button {...btnProps} onClick={toggleDropdown}>
        {children}
      </Button>
      {showDropdown &&
        ReactDOM.createPortal(
          <div ref={dropdownMenuRef} className={`dropdown-menu`}>
            {actions &&
              actions.length &&
              actions.map((action, index) => (
                <div
                  className="dropdown-item"
                  key={index}
                  onClick={() => {
                    action.onClick();
                    setShowDropdown(false);
                  }}
                >
                  {action.label}
                </div>
              ))}
          </div>,
          document.getElementById("portal-root")
        )}
    </div>
  );
};

export default DropdownButton;
