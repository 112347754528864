import React from 'react';
import OffoodLogo from '../../components/brand/OffoodLogo';
import useLangNavigate from '../../hooks/useLangNavigate';
import { usePageTitle } from '../../hooks/useMeta';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
    usePageTitle('termsAndConditions');
    const navigate = useLangNavigate();
    const { t } = useTranslation();
    return (<>
        <div className='p-2'>
            <OffoodLogo width='100px' onClick={() => navigate('/')} />
        </div>
        <iframe title='Terms And Conditions' src={`https://www.iubenda.com/conditions-generales/${t('legal.terms')}`} style={{ width: '100%', height: '100vh' }} />);
    </>);
};

export default TermsAndConditions;