import { createContext, useState } from "react";

const NavigationContext = createContext({
  hasChanged: false,
  setHasChanged: () => {},
  handleNavigateBack: () => {},
});

export const NavigationProvider = ({ children }) => {
  const [hasChanged, setHasChanged] = useState(false);

  const handleNavigateBack = (navigateBack) => {
    if (hasChanged)
      console.log("Unsaved changes. Are you sure you want to leave?");
    else navigateBack();
  };

  return (
    <NavigationContext.Provider
      value={{
        hasChanged,
        setHasChanged,
        handleNavigateBack,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
